.loginInputEditAdmin .p-inputtext {
    background-color: #14161b !important;
    background:#14161b;
    border-width:0;
    align-self: center;
    text-align: center;
    width:170px;
    color: #ffffff;
    margin-top: 10px;
    height:40px;
}

.modalAdd .p-dialog .p-dialog-header{
    background-color: #000000 !important;
}







