.link {
  text-decoration: none !important;
}

.link:hover {
  background-color: #14161b !important;
}

.topview {
  box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
}



.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #435abd !important;

}

.p-inputswitch .p-inputswitch-slider {
  background: #14161b !important;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0px;
  outline-offset: 0px;
  box-shadow: none;
}


/* Dropdown Button */
.dropbtn {
  background-color: #000000;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 7px;
  margin-right: 5px;
  margin-left: 5px;
  z-index: 10;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  z-index: 10;

}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #14161b;
  min-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  border-radius: 10px;

}

/* Links inside the dropdown */
.dropdown-content .link {
  color: #FFFFFF;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  z-index: 10;
  border-radius: 10px;
  margin: 10px;

}

/* Change color of dropdown links on hover */
.dropdown-content .link:hover {
  background-color: #435abd !important;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #435abd;
}

.dropdownprofile {
  position: relative;
  display: inline-block;
  z-index: 10;
}

/* Dropdown Button */
.dropbtnprofile {
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 7px;
  z-index: 10;
  width: 70px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-contentprofile {
  display: none;
  position: absolute;
  background-color: #14161b;
  min-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  border-radius: 10px;
  right: 20px;
}

.dropdown-contentprofile .link {
  color: #ffffff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  z-index: 10;
  border-radius: 10px;
  margin: 10px;
}

/* Change color of dropdown links on hover */
.dropdown-contentprofile .link:hover {
  background-color: #435abd !important;
}

/* Show the dropdown menu on hover */
.dropdownprofile:hover .dropdown-contentprofile {
  display: block;
}


/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdownprofile:hover .dropbtnprofile {
  background-color: #435abd;
}