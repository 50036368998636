.top-right {
  z-index: 999 !important;
}

.p-dialog .p-dialog-content {
  border-radius: 10px;
}

.p-dialog .p-dialog-content {
  background-color: #14161b !important;
}